import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const letemps = () => (
  <Layout>
    <SEO title="Sentier et Chemin - Le temps" />
    <h3 className='underline-title'>Le temps</h3>
    <br />
    <br />
    <div className="table-responsive">
      <p>
        Le temps est venu<br />
        <span className="par-starting"></span>- langue délie-toi!<br />
        <span className="par-starting"></span>- mais ne sois ni affilée ni pendue<br />
        <span className="par-starting"></span>- bouche parle!<br />
        <span className="par-starting"></span>- mais ne gueule ni ne beugle<br />
        <span className="par-starting"></span>- oreilles écoutez!<br />
        <span className="par-starting"></span>- mais soyez fines et délicates<br />
      </p>
      <p>
        Le temps est venu pour que<br />
        <span className="par-starting"></span>l'aveugle dévisage<br />
        <span className="par-starting"></span>le sourd entende<br />
        <span className="par-starting"></span>le muet harangue<br />
      </p>
      <p>
        Le temps est venu<br />
        Ce temps<br />
        Le temps de trente ans <br />
        Le temps du Messie<br />
      </p>
    </div>
    <br />
    <br />
    <Link to="/sentier-et-chemin-table-des-matieres">Table des matières</Link>
  </Layout>
)

export default letemps
